@import "~bootstrap/scss/bootstrap";
@import "~react-modal-video/scss/modal-video.scss";
@import "../css/icons.min.css";

@import "variables";
@import "helper";
@import "common";
@import "preloader";
@import "header";
@import "slider";
@import "support";
@import "section-title";
@import "product";
@import "blog";
@import "footer";
@import "banner";
@import "suscribe";
@import "about";
@import "image-slider";
@import "testimonial";
@import "brand-logo";
@import "timers";
@import "breadcrumb";
@import "sidebar";
@import "shop";
@import "product-details";
@import "cart";
@import "compare";
@import "checkout";
@import "contact";
@import "blog-details";
@import "login-register";
@import "my-account";
@import "404";
@import "video-popup";

.ads-modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0,0,0,0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ads-modal.show {
  display: flex;
}

.ads-modal.hide {
  display: none;
}

.ads-modal-content {
  border: 1px solid #888;
  width: 90%;
  max-width: 480px;
  background-color: honeydew;
  position: relative;
}

.ads-modal-content img {
  max-width: 100%;
}

.ads-text {
  background-color: #fefefe;
  padding: 12px 20px;
}

.ads-text h3 {
  color: #000000;
  font-size: 24px;
}

.ads-text p {
  color: #777;
  font-size: 16px;
}

.ads-button-content {
  display: flex;
  justify-content: end;
  align-items: center;
}

.ads-button-content span {
  color: #3e64ff;
  padding-right: 25px;
  cursor: pointer;
}

.ads-button-content a {
  color: #fff;
  background-color: #3e64ff;
  border-radius: 4px;
  border: none;
  padding: 10px 24px;
}

.ads-close {
  position: absolute;
  top: -12px;
  right: -12px;
  width: 28px;
  height: 28px;
  background-color: #e0e0e6;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 90px;
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}